export enum DocumentTypeEnum {
  IdentityImage = 'IdentityImage',
  PersonalImage = 'PersonalImage',
  ResidenceImage = 'ResidenceImage',
  PassportImage = 'PassportImage',
  MalfunctionImage = 'MalfunctionImage',
  InternalContractImage = 'InternalContractImage',
  FormImage = 'FormImage',
  Other = 'Other',
  SecurityNoteImage = 'SecurityNoteImage',
  SecurityIncidentImage = 'SecurityIncidentImage',
  PatrolCarsReportAttachment = 'PatrolCarsReportAttachment',
  VehicleAndDriverAttachment = 'VehicleAndDriverAttachment',
  FuelCheckUpAttachment = 'FuelCheckUpAttachment',
  ScreenCapture = 'ScreenCapture',
  // Weapons
  GateArmingRequestCommandAttachment = 'GateArmingRequestCommandAttachment',
  WeaponFormAttachment = 'WeaponFormAttachment',
}
